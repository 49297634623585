import React, { useState } from 'react'
import { Outlet } from "react-router";

// Components
// Molecules
import Header from "../molecule/header";
import Sidebar from "../molecule/sidebar";
import Crumb from '../molecule/crumb';

//Context
//Molecules
import { ConfirmDialogProvider } from "../molecule/confirmDialog";
import { ToastContextProvider } from "../molecule/toastContext";
import Banner from '../molecule/banner';

interface SidebarContext {
    sideToggle: boolean,
    toggleMenu: () => void
}

export default function Layout () {
    const [sideToggle, setSideToggle] = useState(false);
    const toggleMenu = () => {
        let toggle = !sideToggle;
        setSideToggle(toggle);
    }

    return (
        <ConfirmDialogProvider>
            <ToastContextProvider>
                <div className='relative'>
                    <Header sideToggle={sideToggle} toggleMenu={toggleMenu}></Header>
                    <Sidebar sideToggle={sideToggle} toggleMenu={toggleMenu}></Sidebar>
                    <div className='lg:ml-[250px] pt-[60px]'>
                        <div className='flex py-1 px-2 justify-center'>
                            <Banner text='Disclaimer: Please contact a customer service representative if any information listed is incorrect'/>
                        </div>
                        <Crumb />
                        <section className="py-2 px-4 h-full">
                            <Outlet />
                        </section>
                    </div>
                </div>
            </ToastContextProvider>
        </ConfirmDialogProvider>
    );
}

export type { SidebarContext };