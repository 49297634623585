import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import _common from '../utility-hooks/common';
import { BiHome } from 'react-icons/bi';

const Crumb = () => {
    const location = useLocation();

    let name = location.pathname;

    if (name) {
        name = _common.Camelcase(name.slice(1));
    }

    if (name !== 'Dashboard') {
        return (
            <div className='py-2 px-4'>
                <div className='flex gap-2 items-center text-sm'>
                    <NavLink to='/dashboard' className='text-gray-500 flexitems-center gap-1'><h3>Home</h3></NavLink>
                    <span className='text-xs text-gray-500'>/</span>
                    <span>{name ?? 'Placeholder'}</span>
                </div>
                <h1 className='text-2xl font-semibold tracking-wide'>{name ?? 'Placeholder'}</h1>
            </div>
        )
    } else {
        return <></>
    }

}

export default Crumb;