import { FC, useState } from "react";
// Components
import Card from "../atom/card";
import DescriptionList from "../molecule/descriptionList";
import SkeletonWrapper from "../molecule/skeletonWrapper";
import Button from "../atom/button";
import Modal from "../molecule/modal";
import AccountDetails from "./accountDetails";

//  API Hooks
import { useAppSelector } from "../redux/app/hooks";
import { useGetDebtsQuery } from "../redux/features/debt/debtApiSlice";
import { useGetEnrollmentDetailsQuery } from "../redux/features/enrollment/enrollmentApiSlice";
import { useGetInfoQuery } from "../redux/features/profile/profileApiSlice";

// utility hooks
import _common from "../utility-hooks/common";

const DashBoardProfileHeader: FC = () => {
    // custom hook grabs profile info data
    const { data: profileInfo = {}, isSuccess } = useGetInfoQuery({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    const profileName: JSX.Element =
        <p className="text-4xl font-bold text-slate-600">
            {_common.Capitalize([profileInfo?.firstName, profileInfo?.lastName])}
        </p>


    return (
        <main>
            <Card>
                <section className="w-full">
                    <p className="text-xl mb-2">Welcome back,</p>
                    <div className="flex-column sm:flex justify-between">
                        <SkeletonWrapper element={profileName} state={isSuccess} />
                        <Button onClick={() => {
                            setIsModalOpen(true)
                        }} customClass="font-bold text-lg text-cyan-600 hover:text-slate-500 focus:outline-none">
                            Account Information
                        </Button>
                    </div>
                </section>
            </Card>
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <AccountDetails />
            </Modal>
        </main>
    )
}

const DebtOverview: FC = () => {
    // custom hook calls get debts api, isSuccess = true when data has been fetched 
    const { isSuccess } = useGetDebtsQuery({})
    // useAppSelector grabs data from global state
    const {
        totalDebts,
        numDebts,
        ids: allDebtIds,
        entities: allDebtEntities

    } = useAppSelector(state => state.debt)

    // global state sorts debts by currentBalance Desc => ids[0] == largest debt
    const largestEnrolledDebtEntity: Record<string, any> = allDebtEntities[allDebtIds[0]]

    const largestEnrolledDebt: JSX.Element = <h1 className='text-2xl'>{_common.FormatCurrency(largestEnrolledDebtEntity?.currentBalance)}</h1>
    const totalEnrolledDebt: JSX.Element = <h1 className='text-2xl'>{_common.FormatCurrency(totalDebts)}</h1>
    const numEnrolledDebt: JSX.Element = <h1 className='text-2xl'>{_common.IfNullReturn(numDebts, "N/A")}</h1>
    const largestEnrolledCreditor: JSX.Element = <h1 className='text-2xl'>{_common.IfNullReturn(largestEnrolledDebtEntity?.currentCreditor, "N/A")}</h1>


    return (
        <main className="my-2">
            <h1 className="text-3xl py-2">Debt Overview</h1>
            <Card>
                <section className="divide-y-2 w-full">
                    <div className='grid grid-cols-2 py-3'>
                        <div className='text-center col-span-2 sm:col-span-1'>
                            <span>Total Enrolled Debt</span>
                            <SkeletonWrapper element={totalEnrolledDebt} state={isSuccess} />
                        </div>
                        <div className='text-center col-span-2 sm:col-span-1'>
                            <span>Largest Enrolled Debt</span>
                            <SkeletonWrapper element={largestEnrolledDebt} state={isSuccess} />
                        </div>
                    </div>
                    <div className='grid grid-cols-2 py-3'>
                        <div className='text-center col-span-2 sm:col-span-1'>
                            <span>Number of Enrolled Debt</span>
                            <SkeletonWrapper element={numEnrolledDebt} state={isSuccess} />
                        </div>
                        <div className='text-center col-span-2 sm:col-span-1'>
                            <span>Largest Enrolled Creditor</span>
                            <SkeletonWrapper element={largestEnrolledCreditor} state={isSuccess} />
                        </div>
                    </div>
                </section>
            </Card>
        </main>
    )
}

const PaymentOverview: FC = () => {
    // custom hook calls get enrollment details api
    const { isSuccess } = useGetEnrollmentDetailsQuery({})
    // fetch enrollment details data from global state
    const { enrollmentDetails } = useAppSelector(state => state.enrollment)

    const paymentHistory: Record<string, any> = {
        "Next Payment Date": _common.IfNullReturn(enrollmentDetails.nextPaymentDate, "N/A"),
        "Amount Due": _common.IfNullReturn(_common.FormatCurrency(enrollmentDetails.nextPaymentAmount), "N/A"),
        "Last Payment Date": _common.IfNullReturn(enrollmentDetails.lastPaymentDate, "N/A"),
        "Last Payment": _common.IfNullReturn(_common.FormatCurrency(enrollmentDetails.lastPaymentAmount), "N/A")
    }
    const accountSummary: Record<string, any> = {
        "Payments Made": _common.IfNullReturn(enrollmentDetails.clearedPayments, "N/A"),
        "Total Payments": _common.IfNullReturn(enrollmentDetails.programLength, "N/A"),
        "Enrollment Balance": _common.IfNullReturn(_common.FormatCurrency(+enrollmentDetails.enrolledBalance), "N/A"),
        "Payment Method": _common.ParsePaymentMethod(+enrollmentDetails.paymentMethod)
    }


    return (
        <main className="py-2">
            <h1 className="text-3xl mb-2">Payments Overview</h1>
            <section className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <div className="w-full rounded-md">
                    <DescriptionList header="Account Number" subHeader={enrollmentDetails.profileId} data={paymentHistory} state={isSuccess} />
                </div>
                <div className="w-full rounded-md">
                    <DescriptionList header="Enrollment Summary" subHeader={enrollmentDetails.basePlan} data={accountSummary} state={isSuccess} />
                </div>
            </section>
        </main>
    )
}

export default function Dashboard() {

    return (
        <main>
            <DashBoardProfileHeader />
            <DebtOverview />
            <PaymentOverview />
        </main>
    )
}

