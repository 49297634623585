import React, { useState } from "react";
import useConfirm from "./confirmDialog";
import Button from "../atom/button";


interface PreviewProps {
  onFileSelect: (file:File | null) => void;
  confirmation?: boolean | undefined;
  label?: string
}

const Preview: React.FC<PreviewProps> = ({ onFileSelect, confirmation, label }) => {
    const [file, setFile] = useState<File | null>(null);
    const [fileUrl, setFileUrl] = useState<string | undefined>(undefined);
    const [fileType, setFileType] = useState<string>("");

    const previewFile = (file: File) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setFileUrl(reader.result as string);
            setFileType(file.type);
        };

    };

    React.useEffect(() => {
        if (file) {
            previewFile(file);
        }
    }, [file]);

    React.useEffect(() => {
        if (fileUrl) {
            userConfirm();
        }
    },[fileUrl])

    const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files ? e.target.files[0] : null;
        setFile(selectedFile);
    };

    const getFilePreview = () => {
        if (fileType.includes("image")) {
            return <img src={fileUrl} alt="preview" />;
        } else if (fileType.includes("pdf")) {
            return <object data={fileUrl} className='min-w-[350px] h-[50vh] min-h-[450px] w-[60vw] max-w-[650px]'><p>Sorry, your browser doesn't support embedded PDFs.</p></object>;
        } else {
            return <p>File type not supported.</p>;
        }
    };

    const confirm = useConfirm();
    // success func
    const onConfirm = () => {
        onFileSelect(file);
        setFile(null);
        setFileUrl('');
        if (hiddenFileInput.current?.value) {
            hiddenFileInput.current.value = '';
        }
    };

    // calls confirm function 
    const userConfirm = async() => {
        let choice = await confirm({
            title: 'File Preview',
            description: getFilePreview(),
            confirmBtnLabel: 'Upload',
            confirmBtnClass: `text-sm bg-[rgb(var(--secondary-color))] hover:bg-[rgba(var(--secondary-color),.85)] text-white active:scale-95 flex items-center rounded-md justify-center font-semibold`
        });

        if (typeof choice === 'boolean') {
            if (choice) {
                onConfirm()
            } else if (!choice) {
                setFileUrl('')
                setFile(null)
                if (hiddenFileInput.current?.value) {
                    hiddenFileInput.current.value = '';
                }
            }
        }
    }

    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    const handleClick = (e:Event) => {
        if( hiddenFileInput && hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    }

    

    return (
        <>
            <button className='bg-primary hover:[bg-primary/90] px-3 py-2 text-white  active:scale-95 transition-all duration-150 rounded-md' onClick={(e:any) => handleClick(e)}>{label ?? 'Upload'}</button>
            <input hidden ref={hiddenFileInput} type='file' onChange={handleFileSelect} accept='.pdf, jpg, .jpeg, .png' />
            {confirmation
                ? null
                : getFilePreview()
            }
        </>

    )
};

export default Preview;