import React, { ReactNode } from "react";

interface ButtonProps {
    children: ReactNode,
    customClass?: string,
    onClick?: () => void
}

const Button: React.FC<ButtonProps> = ({
    children,
    customClass,
    onClick
}) => {

    return (
        <button onClick={onClick} className={customClass ?? 'text-sm bg-inherit text-white transition-all hover:bg-[rgba(255,255,255,.25)] active:scale-95 flex items-center rounded-md justify-center'}>
            <div className='p-2'>{children}</div>
        </button>
    )
}

export default Button;