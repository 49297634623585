import { FC } from 'react'

interface ButtonSpinnerProps {
    color?: string,
    size?: number
}


const ButtonSpinner: FC<ButtonSpinnerProps> = ({ color = "grey" }) => {
    return (
        <div>
            <div className={`mx-2 w-[1.5em] h-[1.5em] border-t-[.25em] border-t-[${color}] border-r-[.25em] border-r-transparent rounded-[50%] m-auto animate-spin`}>
            </div>
        </div>


    )
}

export default ButtonSpinner