import {FC} from "react";
import SkeletonWrapper from "./skeletonWrapper";

interface DescriptionListHeaderProps {
  header: string, 
  subHeader: string | null,
}

interface ListItemProps {
  idx: number, 
  title: string, 
  value: any
  state?: boolean, 
}

interface DescriptionListProps {
  header: string,
  subHeader: string | null, 
  data: Record<string, unknown>,
  state?: boolean 
}


const DescriptionListHeader: FC<DescriptionListHeaderProps> =  ({header = "", subHeader = ""}) =>  {
  return (
    <div className="px-4 py-5 sm:px-6">
      <h3 className="text-lg font-semibold leading-6 text-gray-900">
        {header}
      </h3>
      <p className="mt-1 max-w-2xl text-base text-gray-500">
        {subHeader}
      </p>
    </div>
  );
}

const ListItem: FC<ListItemProps> = ({idx, title, value, state=true}) =>  {
    const color = idx % 2 === 0 ? "bg-gray-50" : "bg-white"
    const header: JSX.Element = <dt className="text-xl font-medium text-gray-500 sm:col-span-5">{title}:</dt>
    const data: JSX.Element = <dd className="mt-1 text-xl text-gray-900 sm:col-span-7 sm:mt-0">{value}</dd>

  return (
    <div className={`${color} px-4 py-5 sm:grid sm:grid-cols-12 sm:gap-5 sm:px-6`}>
      <SkeletonWrapper element={header} state={state} />
      <SkeletonWrapper element={data} state={state} />
    </div>
  );
}

const DescriptionList: FC<DescriptionListProps> = ({header = "", subHeader = "", data = {}, state=true}) => {
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="border-t border-gray-200">
        {header || subHeader ? <DescriptionListHeader header={header} subHeader={subHeader}/> : ""}
        <dl>
            {Object.entries(data).map(([key, value], i) => {
                return <ListItem idx={i} key={key} title={key} value={value} state={state} />
            })}
        </dl>
      </div>
    </div>
  );
}


export default DescriptionList;
