import React from 'react';

const inputTypes = ['text', 'number', 'select', 'date', 'select'] as const;

type Options = {
    label: string,
    value: string | number
}

type InputProps = {
    type: typeof inputTypes[number];
    value:any;
    onChange?: any
    data: Options[];
}

function Input({
    value: initialValue,
    type,
    data,
    onChange,
    ...props
}: InputProps & Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'onChange'> & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
    const [ value, setValue ] = React.useState(initialValue);

    React.useEffect(() => {
        if (onChange) {
            onChange(value);
        }
    }, [value])


    // input and text area untested
    return (
        <>
            {!['select', 'textarea'].includes(type)
                ? <input type={type} value={value} onChange={e => setValue(e.target.value)} {...props} />
                : type === 'select'
                    ? (
                        <select value={value} {...props} onChange={e => setValue(e.target.value)}>
                        {data.map((e:Options, i) => {
                            return (
                                <option key={i} value={e.value}>{e.label}</option>
                            )
                        })}
                        </select>
                    )
                    : <textarea value={value ?? ''} onChange={e => setValue(e.target.value)}></textarea>
            }
        </>
    )
}

export default Input;