import React, { useState, useEffect } from 'react';

type InputProps = {
    value: string | number,
    onChange: (value: string | number) => void,
    debounce?: number
}

function DelayInput({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}: InputProps & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> ) {
    const [value, setValue] = useState(initialValue)
    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <input {...props} value={value} onChange={e => setValue(e.target.value)} />
    )
}

export default DelayInput;