import React, { ReactNode } from 'react';

//atom components
import Card from "../atom/card";
import Button from '../atom/button';

interface AlertProps {
    isOpen?: boolean,
    onClose?: () => {},
    onConfirm?: () => {},
    title?: string,
    description?: string | ReactNode,
    confirmBtnLabel?: string,
    confirmBtnClass?: string,
    customClass?:string
}

const Alert: React.FC<AlertProps> = ({
        isOpen,
        onClose,
        onConfirm,
        title,
        description,
        confirmBtnLabel,
        confirmBtnClass,
        customClass
    }) => {

        return (
            <div id='alertDialog' className={'fixed z-20 w-screen h-screen items-center justify-center bg-[rgba(0,0,0,0.4)] ' + (isOpen ? 'flex' : 'hidden')}>
                <Card customClass={customClass ?? 'bg-white rounded-md min-w-[375px] w-[65vw] max-w-[750px]'}>
                    <div className='alert-Header mb-2 p-2 rounded-t-md bg-primary text-white'>
                        <h1 className='text-xl font-semibold'>{title ?? 'Confirmation'}</h1>
                    </div>
                    <div className='alert-body p-2 flex items-center justify-center'>
                        <div className='text-gray-500'>{description ?? 'Are you sure you want to continue?'}</div>
                    </div>
                    <div className='alert-footer flex justify-end gap-2 p-2'>
                        <Button onClick={onClose} customClass='text-sm bg-inherit text-gray-500 hover:text-black active:scale-95 flex items-center rounded-md justify-center'>Cancel</Button>
                        <Button onClick={onConfirm} customClass={confirmBtnClass ?? `text-sm bg-red-500 hover:bg-[rgba(239,68,68,.85)] text-white active:scale-95 flex items-center rounded-md justify-center font-semibold`}>{confirmBtnLabel ?? 'Confirm'}</Button>
                    </div>
                </Card>
            </div>
        ) 
    }

export type { AlertProps }

export default Alert;
