import { FC } from "react";

// Components
import Card from "../atom/card";
import DescriptionList from "../molecule/descriptionList";

// API Hooks
import { useGetInfoQuery } from "../redux/features/profile/profileApiSlice";

// utility hooks
import _common from "../utility-hooks/common";


// Interface
interface PersonalInfoProps {
    profileInfo: Record<string, any>,
    isSuccess: boolean
}

interface AddressInfoProps {
    addressInfo?: IProfileAddress[] | [],
    isSuccess?: boolean
}

interface IProfileAddress {
    profileId: string,
    addressId: string,
    addressName: string,
    order: number,
    address1: string,
    address2: string,
    city: string,
    state: string,
    zipCode: string

}

interface ContactInfoProps {
    contactInfo?: Record<string, any>[],
    isSuccess: boolean
}

const PersonalInfo: FC<PersonalInfoProps> = ({ profileInfo, isSuccess }) => {
    const accountInfo = {
        "Profile ID": _common.IfNullReturn(profileInfo.profileId, "N/A"),
        "First Name": _common.IfNullReturn(profileInfo.firstName, "N/A"),
        "Middle Name": _common.IfNullReturn(profileInfo.middleName, "N/A"),
        "Last Name": _common.IfNullReturn(profileInfo.lastName, "N/A"),
        "Birthdate": _common.IfNullReturn(profileInfo.birthdate, "N/A"),
        "Last 4 SSN": _common.MaskSSN(profileInfo.ssn),
        "Client Since": _common.IfNullReturn(profileInfo.createdAt, "N/A"),
        "Client Status": _common.IfNullReturn(profileInfo.statusName, "N/A")
    }
    return (
        <section className="mb-10">
            <DescriptionList header="Account Information" subHeader="" data={accountInfo} state={isSuccess} />
        </section>
    )

}

const AddressInfo: FC<AddressInfoProps> = ({ addressInfo = [], isSuccess }) => {
    const addressInformation: JSX.Element[] | JSX.Element =
        addressInfo.length
            ? addressInfo.map((address: Record<string, any>) => {
                const { addressName, address1, address2, city, state, zipCode, addressId } = address
                const addressData = {
                    "Address 1": address1,
                    "Address 2": address2,
                    "City": city,
                    "State": state,
                    "Zip Code": zipCode,
                }
                return (
                    <article key={addressId} className="mb-10">
                        <DescriptionList header={_common.Capitalize(addressName.split(" "))} subHeader={""} data={addressData} state={isSuccess} />
                    </article>

                )
            })
            :
            <article className="mb-10" >
                <Card>
                    <p className="text-2xl text-slate-500">No address on record</p>
                </Card>
            </article>

    return (
        <section>
            {addressInformation}
        </section>
    )
}



const ContactInfo: FC<ContactInfoProps> = ({ contactInfo = [], isSuccess }) => {
    const contactInformation: Record<string, any> | null =
        contactInfo.length
            ? contactInfo.reduce((prev: Record<string, any>, curr: Record<string, any>) => {
                const { contactName, value } = curr
                const contactData = {
                    [contactName]: value
                }
                return { ...prev, ...contactData }
            }, {})
            : null

    return (
        <section>
            {contactInformation
                ? <DescriptionList data={contactInformation} header="Contact Information" subHeader={""} state={isSuccess} />
                :
                <Card>
                    <p className="text-2xl text-slate-500">No address on record</p>
                </Card>
            }
        </section>
    )
}

const AccountDetails: FC = () => {
    const { data: profileInfo = {}, isSuccess } = useGetInfoQuery({});

    return (
        <main>
            <PersonalInfo profileInfo={profileInfo} isSuccess={isSuccess} />
            <AddressInfo addressInfo={profileInfo.profileAddresses} isSuccess={isSuccess} />
            <ContactInfo contactInfo={profileInfo.profileContacts} isSuccess={isSuccess} />
        </main>
    )
}

export default AccountDetails