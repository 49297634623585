import { apiSlice } from "../../app/api/apiSlice";

export const documentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDocuments: builder.query({
      query: (profileId) => ({
        url: `/document`,
        method: "GET",
      }),
      providesTags: ["Documents"],
      transformResponse: (res) => res.data.sort((a,b) => {
          const aCreatedAt = a.createdAt || '0000-00-00';
          const bCreatedAt = b.createdAt || '0000-00-00';
      
          return new Date(bCreatedAt) - new Date(aCreatedAt);
        })
    }),
    getDocumentPreview: builder.mutation({
      query: (documentId) => ({
        url: `/document/${documentId}/preview`,
        method: "GET",
      }),
      transformResponse: (res) => res.data
    }),
    uploadDocument: builder.mutation({
      query: ({ id, file }) => {

        const formData = new FormData();

        // Add properties to formData
        formData.append("title", file.name);
        formData.append("file", file);
        formData.append("category", "Client Uploaded");

        return {
          url: `/document`,
          method: "POST",
          body: formData,
          dataType: "json",
          enctype: 'multipart/form-data',
          processData: false,  // Important!
          contentType: false,
        };
      },
      invalidatesTags: ["Documents"],
      transformResponse: (res) => res.data
    }),
  }),
});

export const {
  useGetDocumentsQuery,
  useGetDocumentPreviewMutation,
  useUploadDocumentMutation,
} = documentApiSlice;
