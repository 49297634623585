import React, { FC } from "react";
import { BiX, BiCheckCircle, BiXCircle, BiErrorCircle, BiInfoCircle } from "react-icons/bi";
import { IconContext } from "react-icons/lib";
import useToast from "./toastContext";

interface ToastProps {
    message: string,
    type: 'Success' | 'Warning' | 'Error' | 'Info',
    id: string,
    autoDeleteDuration?:number,
    onClick?: Function
}

const Toast: FC<ToastProps> = ({
    message,
    type,
    id,
    onClick
}) => {

    let icon;

    switch (type) {
        case 'Success':
            icon = <BiCheckCircle />
            break;
        case 'Warning':
            icon = <BiErrorCircle />
            break;
        case 'Error':
            icon = <BiXCircle />
            break;
        case 'Info':
            icon = <BiInfoCircle />
            break;
    }

    const handleClick:React.MouseEventHandler<HTMLButtonElement> = e => {
        onClick?.(e.currentTarget.id);        
    } 

    return (
        <>
            <IconContext.Provider value={{size: '1.375em'}}>
                <div className={`p-1 w-[300px] relative rounded-md toast-${type} overflow-hidden toast-animation`}>
                    <button id={String(id)} onClick={handleClick} className='absolute top-1 right-1 hover:text-gray-300 transition-colors'><BiX /></button>
                    <div className='flex items-center'>
                        <div className='flex-shrink px-2'>
                            <IconContext.Provider value={{size:'3em'}}>
                                {icon}
                            </IconContext.Provider>
                        </div>
                        <div className='content'>
                            <p className='text-lg font-semibold tracking-wide'>{type}</p>
                            <p className='mb-2 break-words whitespace-normal'>{message}</p>
                        </div>
                    </div>
                    <div className='timer absolute w-full bottom-0 left-0 overflow-hidden'>
                        <div className='h-1 bg-gray-100 timebar' style={{ '--duration':5 } as React.CSSProperties}></div>
                    </div>
                </div>
            </IconContext.Provider>
        </>
    )
}

export default Toast;
export type { ToastProps };