import React, {ReactNode} from 'react';

type BadgeProps = {
    label: string,
    className: string,
}

function Badge({
    label, 
    className
}: BadgeProps) {
    return <span className={className}>{label}</span>
}

export default Badge;