import React from 'react';
import { BiInfoCircle, BiX } from 'react-icons/bi'
import { IconContext } from 'react-icons/lib';

type BannerProps = {
    text:string,
    className?: string
}

export default function Banner ({
    text,
    className
}: BannerProps) {
    
    const [ visible, setVisible ] = React.useState<boolean>(true);

    return (
        <IconContext.Provider value={{size: '2rem'}}>
            <div className={(visible ?'' : 'hidden ') + (className ?? 'w-full bg-secondary p-2 flex items-center justify-between text-white rounded-md')}>
                <div className='flex gap-2 items-center'>
                    <span><BiInfoCircle /></span>
                    <span>{text ?? ''}</span>
                </div>
                <span className='hover:cursor-pointer' onClick={() => setVisible(false)}><BiX /></span>
            </div>
        </IconContext.Provider>
    )
}