import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";
import { setDebtFigures } from "./debtSlice";

export const debtAdapter = createEntityAdapter({
  selectId: (debt) => debt.liabilityId,
  sortComparer: (a, b) => b.currentBalance - a.currentBalance
});

const initialState = debtAdapter.getInitialState();

export const debtApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDebts: builder.query({
      query: () => ({
        url: `/debt`,
        method: "GET",
      }),
      transformResponse: (res) => {
        return debtAdapter.setAll(initialState, res.data);
      },
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          const { ids, entities } = data;

          const totalDebts = ids.reduce((prev, curr) => {
            const debtData = data.entities[curr];
            return debtData.currentBalance
              ? prev + debtData.currentBalance
              : prev;
          }, 0);

          dispatch(
            setDebtFigures({
              totalDebts,
              ids,
              entities,
            })
          );

          return data;
        } catch (err) {
          console.log(err);
        }
      },
      providesTags: (result, error, arg) => {
        return [
          { type: "Debt", id: "LIST" },
          ...result.ids.map((id) => ({ type: "Debt", id })),
        ];
      },
    }),
  }),
});


export const {
  selectAll: selectAllPayments, 
  selectById: selectPaymentById, 
  selectIds: selectPaymentIds
} = debtAdapter.getSelectors(state => state.debt)

export const { useGetDebtsQuery } = debtApiSlice;
