import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

// views
import ErrorPage from "./view/error";
import Dashboard from "./view/dashboard";
import Login from "./view/login";
import Transaction from "./view/transaction"
import Document from "./view/document";
import Debt from "./view/debt";

// helper components
import AuthGuard from "./organism/authguard";
import Layout from "./organism/layout";



const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            {/* public routes */}
            <Route path='login' element={<Login />} />
            <Route path='/' element={<Login />} />


            {/* private routes */}
            <Route element={<AuthGuard />}>
                <Route element={<Layout />}>
                    <Route path='dashboard' element={<Dashboard />} />
                    <Route path='transaction' element={<Transaction />} />
                    <Route path='document' element={<Document />} />
                    <Route path='debt' element={<Debt />} />
                </Route>
            </Route>

            {/* catchall */}
            <Route path="*" element={<ErrorPage />}/>
        </>
    )
)

export default function Router () {
    return <RouterProvider router = {router} />
}