import React from "react";
import { Link } from "react-router-dom";
import Card from "../atom/card";

const ErrorPage = () => {
    return(
        <div className='h-screen flex justify-center items-center'>
            <Card>
                <div className='content px-4 py-8'>
                    <h1 className="text-2xl font-semibold">Oops! Looks like you've made a wrong turn.</h1>
                    <div className='my-4'></div>
                    <p>Please click <span className='text-blue-700'><Link to='/dashboard'>here</Link></span> to go back!</p>
                </div>
            </Card>
        </div>
    )
}

export default ErrorPage;