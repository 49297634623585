import React from 'react';

import { SidebarContext } from '../organism/layout';

//icons styling
import { IconContext } from 'react-icons/lib';

//icons
import { BiFolderOpen, BiGridAlt, BiTable, BiX } from 'react-icons/bi';
import { FaPiggyBank } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

const Sidebar: React.FC<SidebarContext> = ({ sideToggle, toggleMenu }) => {
	const afterClick = () => {
		if (sideToggle) {
			toggleMenu();
		}
	};
	return (
		<>
			<div
				onClick={toggleMenu}
				className={`${
					sideToggle ? 'absolute' : 'hidden'
				} h-screen w-screen  bg-[rgba(0,0,0,0.4)] lg:w-fit lg:hidden`}></div>
			<div
				className={`{h-screen fixed w-[250px] bg-white drop-shadow-lg z-10 transition-transform duration-150 h-full ${
					sideToggle ? '' : 'translate-x-[-250px]'
				} lg:translate-x-[0px]  }`}>
				<div className="sidenav-header bg-[rgb(var(--primary-color))] h-[60px] flex justify-center items-center relative">
					<img
						src="assets/logo-white.png"
						className="h-12"></img>
					<button
						type="button"
						onClick={toggleMenu}
						className={`${
							sideToggle ? '' : 'hidden'
						} absolute top-1 right-1 text-white`}>
						<IconContext.Provider value={{ size: '1.5em' }}>
							<BiX />
						</IconContext.Provider>
					</button>
				</div>
				<IconContext.Provider value={{ size: '1em' }}>
					<div
						aria-label="side nav-bar"
						id="sidebar"
						className="p-2 px-4 flex flex-col gap-2">
						<NavLink
							onClick={afterClick}
							to="/dashboard">
							<div>
								<span className="flex items-center gap-4 px-4">
									<BiGridAlt />
									Dashboard
								</span>
							</div>
						</NavLink>
						<NavLink
							onClick={afterClick}
							to="/document">
							<div>
								<span className="flex items-center gap-4 px-4">
									<BiFolderOpen />
									Document
								</span>
							</div>
						</NavLink>
						<NavLink
							onClick={afterClick}
							to="/transaction">
							<div>
								<span className="flex items-center gap-4 px-4">
									<BiTable />
									Transaction
								</span>
							</div>
						</NavLink>
						<NavLink
							onClick={afterClick}
							to="/debt">
							<div>
								<span className="flex items-center gap-4 px-4">
									<FaPiggyBank />
									Debt
								</span>
							</div>
						</NavLink>
					</div>
				</IconContext.Provider>
			</div>
		</>
	);
};

export default Sidebar;
