import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";
import { setEnrollmentDetails, setPayments } from "./enrollmentSlice";

export const paymentAdapter = createEntityAdapter({
    selectId: (payment) => payment.enrollmentDetailId,
    sortComparer: (a, b) => {
      const aClearedDate = a.clearedDate || '0000-00-00';
      const bClearedDate = b.clearedDate || '0000-00-00';
  
      return new Date(bClearedDate) - new Date(aClearedDate);
    },
  });

const initialState = paymentAdapter.getInitialState();

export const enrollmentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEnrollmentDetails: builder.query({
      query: () => ({
        url: `/enrollment/info`,
        method: "GET",
      }),
      transformResponse: (res) => {
        return res.data;
      },
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setEnrollmentDetails(data))
          return data;
        } catch (err) {
          console.log(err);
        }
      },
      providesTags: ["Enrollment"],
    }),
    getEnrollmentPayments: builder.query({
      query: (profileId) => ({
        url: `/enrollment/payments`,
        method: "GET",
      }),
      transformResponse: (res) => {
        return paymentAdapter.setAll(initialState, res.data);
      },
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setPayments(data))
          return data;
        } catch (err) {
          console.log(err);
        }
      },
      providesTags: ["Payment"],
    }),
  }),
});

export const {
    selectAll: selectAllPayments, 
    selectById: selectPaymentById, 
    selectIds: selectPaymentIds
} = paymentAdapter.getSelectors(state => state.enrollment)


export const { useGetEnrollmentDetailsQuery, useGetEnrollmentPaymentsQuery } =
  enrollmentApiSlice;

  
