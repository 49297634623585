import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";
import authReducer from '../features/auth/authSlice'
import debtReducer from '../features/debt/debtSlice'
import enrollmentReducer from '../features/enrollment/enrollmentSlice'

export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authReducer,
        debt: debtReducer,
        enrollment: enrollmentReducer,
    },
    middleware: getDefaultMiddleware => 
    getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: true
})

// export type RootState = ReturnType<typeof store.getState>
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch