import React from 'react';

import { SidebarContext } from '../organism/layout';

// Atom Components
import Button from '../atom/button';

//icon styling
import { IconContext } from 'react-icons/lib';

//icons
import { BiUser, BiMenu } from 'react-icons/bi';
import { useLogoutMutation } from '../redux/features/auth/authApiSlice';

const Header: React.FC<SidebarContext> = ({ toggleMenu }) => {
	const [logout] = useLogoutMutation();

	const handleLogout = async () => {
		logout({});
	};

	const [dropdown, setDropdown] = React.useState<boolean>(false);

	React.useEffect(() => {}, [dropdown]);

	return (
		<IconContext.Provider value={{ size: '2em' }}>
			<header className="bg-primary h-[60px] w-full flex justify-between lg:justify-end items-center px-3 fixed z-10">
				<div className="mobile-nav-toggle lg:hidden">
					<Button onClick={toggleMenu}>
						<BiMenu />
					</Button>
				</div>
				<div className="header-nav flex gap-2">
					<div className="relative">
						<Button onClick={() => setDropdown(!dropdown)}>
							<BiUser />
						</Button>
						{dropdown ? (
							<ul className="bg-transparent text-white absolute rounded-md overflow-hidden right-0 mt-2 w-40 py-2 drop-shadow-md z-10">
								<li className="w-full bg-primary hover:bg-gray-400 transitions-colors duration-100">
									<button
										onClick={handleLogout}
										className="w-full py-2 bg-inherit">
										<span className="">Log Out</span>
									</button>
								</li>
							</ul>
						) : null}
					</div>
				</div>
			</header>
		</IconContext.Provider>
	);
};

export default Header;
