import { apiSlice } from "../../app/api/apiSlice";
import { setCredentials, logOut } from "./authSlice";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: credentials => ({
                url: '/user/auth',
                method: 'POST',
                body: credentials
            }),
            async onQueryStarted(body, {dispatch, queryFulfilled}){
                try{
                    const {data} = await queryFulfilled
                    if (data.data?.token) {
                        dispatch(setCredentials({ token: data.data.token, clientId: data.data.clientId }));
                      }

                }catch(err){
                    console.log(err)
                }
            }
        }),
        reauth: builder.mutation({
            query: () => ({
                url: '/user/refresh-token',
                method: 'POST',
            }),
            async onQueryStarted(body, {dispatch, queryFulfilled}){
                try{
                    const {data} = await queryFulfilled
                    if (data.data?.token) {
                        dispatch(setCredentials({ token: data.data.token, user: data.data.clientId }));
                      }

                }catch(err){
                    console.log(err)
                }
            }
        }), 
        logout: builder.mutation({
            query: () => ({
                url: '/user/revoke-token',
                method: 'POST'
            }), 
            async onQueryStarted(body, {dispatch, queryFulfilled}){
                try{
                    await queryFulfilled
                    dispatch(logOut())
                }catch(err){
                    console.log(err)
                }
            }
        })
    })
})

export const {
    useLoginMutation, 
    useReauthMutation,
    useLogoutMutation
} = authApiSlice