import { FC, ReactElement } from "react";
import Skeleton from "../atom/skeleton";

interface SkeletonWrapperProps {
    element: JSX.Element | ReactElement,
    state?: boolean
}

const SkeletonWrapper: FC<SkeletonWrapperProps> = ({element, state = false}) => {
    if(!state) return <Skeleton />
    return element
}

export default SkeletonWrapper