import React from 'react';

// Components
import Table from '../organism/table';
import Upload from '../organism/upload';
import Card from '../atom/card';

// API Hooks
import { useGetDocumentsQuery } from '../redux/features/document/documentApiSlice';
import { useGetDocumentPreviewMutation } from '../redux/features/document/documentApiSlice';

const Document = ({
}) => {
    // API/AUTH
    const {data: documents} = useGetDocumentsQuery({})
    const [trigger] = useGetDocumentPreviewMutation()
    
    const [loading, setLoading] = React.useState<boolean>(true)
    const [data, setData] = React.useState([])

    React.useEffect(() => {
        if (documents) {
            setLoading(false)
            setData(documents)
        }
    }, [documents])

    const Preview = async (id: 'string') => {
        try{
            const data = await trigger(id).unwrap()
            const linkSource = `data:${data.contentType};base64,${data.content}`;
            const downloadLink = document.createElement('a');
            document.body.appendChild(downloadLink);
    
            downloadLink.href = linkSource;
            downloadLink.target = '_self';
            downloadLink.download = `${data.title}${data.fileExtension}`;
            downloadLink.click();
            document.body.removeChild(downloadLink);

        }catch(e){
            console.log(e)
        }
    }

    const columns = [
        {
            id: 'name',
            header: 'File Name',
            accessorKey: 'name',
            cell: (ctx:any) => {
                const { name, documentId } = ctx.row.original;
                return (
                    <span onClick={() => Preview(documentId)} className='text-blue-500 underline underline-offset-2 hover:cursor-pointer'>{name}</span>
                )
            }
        },
        {
            id: 'title',
            header: 'File Title',
            accessorKey: 'title',
            cell: (ctx:any) => ctx.getValue()
        },
        {
            id: 'typeName',
            header: 'File Type',
            accessorKey: 'typeName',
            cell: (ctx:any) => ctx.getValue()
        },
        {
            id: 'createdAt',
            header: 'Created Date',
            cell: (ctx:any) => {
                const { createdAt } = ctx.row.original;
                const currentDate = new Date(createdAt).toLocaleDateString('en-US')
                return <span>{ currentDate }</span>
            }
        }
    ]

    return (
        <main>
            <Card>  
                <section className='w-full flex justify-end mb-2'>
                    <Upload />
                </section>
                <Table data={data} loading={loading} search={true} pageSize={10} columns={columns} />
            </Card>
        </main>
    )
}

export default Document;