import { createSlice } from "@reduxjs/toolkit";

const enrollInit = {
    enrollmentId: "",
    enrolledBalance: "",
    paymentMethod: "",
    profileId: "",
    basePlan: "",
    enrollmentFee: 0,
    programLength: 0,
    firstPaymentDate: "",
    firstPaymentAmount: 0,
    firstPaymentClearedDate: "",
    firstPaymentClearedAmount: 0,
    nextPaymentDate: "",
    nextPaymentAmount: 0,
    initialFeeAmount: 0,
    lastPaymentStatus: "",
    lastPaymentStatusName: "",
    lastPaymentDate: "",
    lastPaymentAmount: 0,
    cancelledDate: "",
    cancelledBy: "",
    cancelDisposition: "",
    pausedDate: "",
    pausedBy: "",
    feeType: "",
    clearedPayments: 0,
    totalPayments: 0

}

const enrollmentSlice = createSlice({
    name: "enrollment", 
    initialState: {
        enrollmentDetails: enrollInit,
        ids: [],
        entities: {},
    },
    reducers: {
        setEnrollmentDetails: (state, action) => {
            const data  = action.payload
            state.enrollmentDetails = data;
        }, 
        setPayments: (state, action) => {
            const {ids, entities} = action.payload
            state.ids = ids;
            state.entities = entities;
        }, 
    }
})

export const {setEnrollmentDetails, setPayments} = enrollmentSlice.actions;


export default enrollmentSlice.reducer;
