import { createSlice } from "@reduxjs/toolkit";

const debtSlice = createSlice({
    name: "debt", 
    initialState: {
        numDebts: 0,
        totalDebts: 0,
        ids: [],
        entities: {},
    },
    reducers: {
        setDebtFigures: (state, action) => {
            const { totalDebts, ids, entities } = action.payload
            state.numDebts = ids.length;
            state.totalDebts = totalDebts;
            state.ids = ids; 
            state.entities = entities;
        }, 
    }
})

export const {setDebtFigures} = debtSlice.actions;



export default debtSlice.reducer;

