import React, { ReactNode } from "react";

interface CardProps {
    children: ReactNode,
    customClass?: string 
}

const Card: React.FC<CardProps> = ({
    children,
    customClass
}) => {
    return (
        <div className={customClass ?? 'bg-white rounded-md flex flex-col justify-center items-center p-4 shadow-sm border border-gray-200'}>
            {children}
        </div>
    )
}

export default Card;