import React, { useState } from 'react';

// Components
import Table from '../organism/table';
import Card from '../atom/card';
import Badge from '../atom/badge'
import Skeleton from '../atom/skeleton';

// Auth
import { useSelector } from 'react-redux';
import { selectClientId } from "../redux/features/auth/authSlice";


// API Hooks
import { useAppSelector } from '../redux/app/hooks';
import { useGetEnrollmentPaymentsQuery } from '../redux/features/enrollment/enrollmentApiSlice';
import { useGetEnrollmentDetailsQuery } from '../redux/features/enrollment/enrollmentApiSlice';
import _common from '../utility-hooks/common';

const EnrollmentDetails = () => {
  const { isSuccess } = useGetEnrollmentDetailsQuery({})
  const { enrollmentDetails } = useAppSelector(state => state.enrollment)

  const [loading, setLoading] = useState<boolean>(true)

  React.useEffect(() => {
    if (enrollmentDetails) {
      setLoading(false)
    }
  }, [enrollmentDetails, isSuccess])

  return (
    <main className='grid gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mb-4'>
      <Card>
        <h3 className='font-semibold text-gray-500 text-lg tracking-wide mb-4'>Next Payment Date</h3>
        { !loading
          ? <span className='text-2xl font-semibold mb-4'>{ (_common.IfNullReturn(enrollmentDetails.nextPaymentDate, "N/A")) }</span>
          : <Skeleton size='2rem'/>
        }
      </Card>
      <Card>
        <h3 className='font-semibold text-gray-500 text-lg tracking-wide mb-4'>Amount Due</h3>
        { !loading
          ? <span className='text-2xl font-semibold mb-4'>{ _common.IfNullReturn(_common.FormatCurrency(enrollmentDetails.nextPaymentAmount), "N/A") }</span>
          : <Skeleton size='2rem'/>
        }
      </Card>
      <Card>
        <h3 className='font-semibold text-gray-500 text-lg tracking-wide mb-4'>Payments Made</h3>
        { !loading
          ? <span className='text-2xl font-semibold mb-4'>{ (enrollmentDetails.clearedPayments ?? 'N/A') }</span>
          : <Skeleton size='2rem'/>
        }
      </Card>
      <Card>
        <h3 className='font-semibold text-gray-500 text-lg tracking-wide mb-4'>Total Payments</h3>
        { !loading
          ? <span className='text-2xl font-semibold mb-4'>{ _common.IfNullReturn(enrollmentDetails.programLength, "N/A") }</span>
          : <Skeleton size='2rem'/>
        }
      </Card>
    </main>
  )
  
}

const TransactionTable = () => {
      // API/AUTH
      const clientId = useSelector(selectClientId)
      const {data: enrollmentPayments} = useGetEnrollmentPaymentsQuery(clientId)
    
      const [loading,setLoading] = React.useState<boolean>(true)
      const [data, setData] = React.useState([]);

      React.useEffect(() => {
        if (enrollmentPayments) {
          let arr:any = [];
          if (enrollmentPayments.ids.length > 0) {
            enrollmentPayments.ids.forEach((e:any )=> {
              arr.push(enrollmentPayments.entities[e])
            })
          }

          setData(arr);
          setLoading(false);
        }
      }, [enrollmentPayments])

      const columns = [
         {
          id: 'paymentName',
          header: 'Name',
          accessorKey: 'paymentName',
          cell: (ctx:any) => ctx.getValue(),
        },
        {
          id: 'processedDate',
          header: 'Due Date',
          cell: (ctx:any) => {
            const { processedDate } = ctx.row.original;
            const currentDate = new Date(processedDate).toLocaleDateString('en-US')
            return <span>{ currentDate }</span>
          },
        },
        {
          id: 'statusName',
          header: 'Payment Status',
          accessorKey: 'statusName',
          cell: (ctx:any) => {
            const { statusName } = ctx.row.original ?? '';

            return <Badge className={`badge-md font-semibold text-white status-${statusName.toLowerCase()}`} label={statusName} />

          }
        },
        {
          id: 'amount',
          header: 'Payment Amount',
          cell: (ctx:any) => {
            const {amount} = ctx.row.original ?? 0;
            return <span>${amount.toFixed(2) ?? ''}</span>
          }
        }, 
        {
          id: 'clearedDate', 
          header: 'Payment cleared',
          cell: (ctx:any) => {
            const {clearedDate} = ctx.row.original ?? 0;
            return clearedDate === null ? "" : <span>${_common.FormatDate(clearedDate)}</span>
          }
        }
      ];
    
    return (
        <main>
          <Card>
            <Table 
              pageSize={10} 
              search={true} 
              loading={loading} 
              data={data} 
              columns={columns}/>
          </Card>
        </main>
    )
}

export default function Transaction () {
  return (
    <main>
      <EnrollmentDetails />
      <TransactionTable />
    </main>
  )
}