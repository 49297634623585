// helper functions

const _common = {
  GenerateNumber: (): number => {
    return Math.floor(Math.random() * 999999999);
  },

  Camelcase: (param: string): string => {
    const list = param.split(" ");
    let updated = list.map((x: string) => {
      return x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase();
    });
    return updated.join(" ");
  },
  Capitalize: (param: string[]): string => {
    let res: string = ""
    param.forEach((s, i, arr) => {
      if(s) res += s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
      if(i < arr.length - 1) res += " "
    })

    return res
  },
  ParsePaymentMethod: (param: number): string => {
    const arr = ["ACH", "Credit", "Debit"]
    return arr[param]
  },
  IfNullReturn: function (obj: any, returnVal: any): any {
    const dateRegex: RegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?$/;

    if (obj === null || obj === undefined) return returnVal;
    if (typeof obj === 'string') {
      if (dateRegex.test(obj)) return this.FormatDate(obj);
    }
    return obj;
  },
  FormatCurrency: (
    amount: number | null | undefined = 0,
    currency: string = "USD",
    locale: string = "en-US"
  ): string => {
    if (amount === null || amount === undefined) {
      amount = 0;
    }

    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
    }).format(amount);
  },
  FormatDate: (params: string) : string => {
    const date = new Date(params);
    const formatter = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  
    return formatter.format(date);
  },
  MaskSSN: (ssn: string | undefined | null, maskChar: string = 'X') : string => {
    const validationRegex: RegExp = /^(?!000)(?!666)(?!9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/

    if (ssn === undefined || ssn === null) return 'No SSN on record';
    if(validationRegex.test(ssn)) return ssn.replace(/^(?!000)(?!666)(?!9\d{2})\d{3}(?!00)\d{2}(?!0{4})(\d{4})$/, (match, p1) => `${maskChar.repeat(3)}-${maskChar.repeat(2)}-${p1}`);
    return "Invalid SSN on record"

    }
};

export default _common;
