import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: "auth", 
    initialState: {clientId: null, token: null},
    reducers: {
        setCredentials: (state, action) => {
            const { clientId, token } = action.payload
            state.clientId = clientId; 
            state.token = token
        }, 
        logOut: (state, action) => {
            state.clientId = null; 
            state.token = null
        }
    }
})

export const {setCredentials, logOut} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentToken = (state) => state.auth.token;
export const selectClientId = (state) => state.auth.clientId;
