import  React, { FC } from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: FC<ModalProps> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  const modalSize =
    'w-full h-full md:w-[80%] md:h-[80%]';

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50">
      <div className={`${modalSize} overflow-y-auto bg-white rounded-lg shadow-lg p-4 md:p-6`}>
        {children}
        <button
          className="absolute top-2 right-5 text-5xl font-bold text-gray-900 md:text-white hover:text-slate-500 focus:outline-none"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
    </div>
  );
};

export default Modal;