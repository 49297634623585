import React from 'react';
import { useSelector } from 'react-redux';
import useConfirm from '../molecule/confirmDialog';
import Preview from '../molecule/preview';
import { selectClientId } from '../redux/features/auth/authSlice';
import { useUploadDocumentMutation } from '../redux/features/document/documentApiSlice';

const Upload = () => {
    const clientId = useSelector(selectClientId)

    const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
    const [fetch, result] = useUploadDocumentMutation()

    const handleFileSelect = (file: File | null) => {
        setSelectedFile(file);
    };

    React.useEffect(() => {
        async function upload(){
            if (selectedFile) {
                // api logic
                await fetch({id: clientId, file: selectedFile})
            }
        }

        upload()
    }, [selectedFile])


    return (
        <>
            <Preview onFileSelect={handleFileSelect} confirmation={true} />
        </>
    )
}

export default Upload