import { FC, useEffect, useState } from "react";
import { useLocation, Navigate, Outlet, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../redux/features/auth/authSlice";
import { useReauthMutation } from "../redux/features/auth/authApiSlice";

const AuthGuard: FC = () => {
  const token = useSelector((state) => selectCurrentToken(state));
  const [refreshed, setRefreshed] = useState(false);
  const [reauth, reauthProps] = useReauthMutation();

  useEffect(() => {
    console.log("in use effect");
    const handleReauth = async () => {
      if (!token) {
        await reauth({});
      }
      setRefreshed(true);
    };

    handleReauth();
  }, [reauth, token]);

  if (token) return <Outlet />;

  if (refreshed) return <AuthWrapper reauthProps={reauthProps} token={token} />;

  return <div className='h-screen flex justify-center items-center'><div className='h-16 w-16 animate-spin border-8 border-t-[rgb(var(--primary-color))] rounded-full border-gray-300'></div></div>
};

interface AuthWrapperProps {
  reauthProps: any;
  token: string | null;
}

const AuthWrapper: FC<AuthWrapperProps> = ({ reauthProps, token }) => {
    const { isError, error } = reauthProps;
    const location = useLocation();
    
    if (isError) {
    console.log(error);
    return (
        <>
        <p>ERROR</p>
        <Link to="login" >Login</Link>
        </>
    )
    }
    
    return token ? <Outlet /> : <Navigate to="/login" state={{ from: location }}  replace />;
    };
    
    export default AuthGuard;


