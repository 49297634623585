import {createContext, ReactNode, useContext, useState, FC, Dispatch, useRef, useCallback} from 'react';

import Confirm, {AlertProps} from './alert';

interface ConfirmDialogProps {
    children: ReactNode,
}

const dispatch:Dispatch<{}> = () => {};

const ConfirmDialog = createContext(dispatch);

const ConfirmDialogProvider: FC<ConfirmDialogProps> = ({ 
    children 
}) => {
    const [state, setState] = useState({ isOpen: false });
    const ref:any = useRef();

    const confirm = useCallback((data:AlertProps) => {
        return new Promise ((resolve) => {
            setState({ ...data, isOpen: true })
            ref.current = (choice:boolean) => {
                resolve(choice)
                setState({ isOpen: false })
            }
        })
    }, [ setState ]);

    return (
        <ConfirmDialog.Provider value={confirm}>
            <Confirm 
                {...state}
                onClose={(() => ref.current(false)) ?? false}
                onConfirm={(() => ref.current(true)) ?? true}
            />
            {children}
        </ConfirmDialog.Provider>
    )
}

export default function useConfirm() {
    return useContext(ConfirmDialog); 
}

export { ConfirmDialogProvider };
