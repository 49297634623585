import React from 'react';

type SkeletonProp = {
    size?: string;
}

const Skeleton: React.FC<SkeletonProp> = ({ size }) => {
    return <div className='animate-pulse w-full rounded-sm bg-slate-300' style={{height: (size ?? '1.5rem')}}></div>
}

export default Skeleton;