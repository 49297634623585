import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../redux/features/auth/authApiSlice';
import { useForm, SubmitHandler } from 'react-hook-form';
import ButtonSpinner from '../atom/buttonSpinner';

type FormValues = {
	username: string;
	password: string;
};

const LoginForm: FC = () => {
	const navigate = useNavigate();
	const {
		register,
		reset,
		handleSubmit,
		formState: { errors },
		setError,
	} = useForm<FormValues>({
		defaultValues: {
			username: '',
			password: '',
		},
	});

	const [login, { isLoading }] = useLoginMutation();

	const handleLogin: SubmitHandler<FormValues> = async (data) => {
		const { username, password } = data;
		try {
			await login({ username: username, password: password }).unwrap();
			reset();
			navigate('/dashboard');
		} catch (err) {
			setError(
				'password',
				{
					type: 'Incorrect',
					message: 'Incorrect username or password',
				},
				{ shouldFocus: true }
			);
		}
	};

	return (
		<div className="w-full max-w-md space-y-8">
			<div>
				<img
					className="mx-auto h-[75px] w-auto mb-10"
					src="assets/logo.png"
					alt="Logo"
				/>
				<h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-gray-900">
					Please login to your client portal.
				</h2>
			</div>
			<form
				className="mt-8 space-y-6"
				onSubmit={handleSubmit(handleLogin)}>
				<input
					type="hidden"
					name="remember"
					defaultValue="true"
				/>
				<div className="-space-y-px rounded-md shadow-sm">
					<div>
						{errors.username && (
							<p
								className="text-red-500 text-sm mb-2"
								role="alert">
								{errors.username?.message}
							</p>
						)}
						<label
							htmlFor="username"
							className="sr-only">
							Username
						</label>
						<input
							id="username"
							autoComplete="username"
							className="relative p-2 block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							placeholder="Username"
							{...register('username', {
								required: 'Username is required',
							})}
						/>
					</div>
					<div>
						<label
							htmlFor="password"
							className="sr-only">
							Password
						</label>
						<input
							id="password"
							type="password"
							autoComplete="password"
							className="relative p-2 block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							placeholder="Password"
							{...register('password', {
								required: 'Password is required',
							})}
						/>
						{errors.password && (
							<p
								className="text-red-500 text-sm mt-2"
								role="alert">
								{errors.password?.message}
							</p>
						)}
					</div>
				</div>
				<div>
					<button
						type="submit"
						className="group relative flex w-full justify-center rounded-md bg- px-3 py-2 text-sm font-semibold text-white bg-cyan-800 hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						disabled={isLoading}>
						{isLoading && <ButtonSpinner color="white" />}
						Sign in
					</button>
				</div>
			</form>
		</div>
	);
};

export default LoginForm;
