import { useEffect } from 'react';
import Router from './Router';

function App() {
	useEffect(() => {
		document.title = process.env.REACT_APP_TITLE || '';
	}, []);

	return <Router />;
}

export default App;
