import { FC, useState, Dispatch, SetStateAction } from 'react';

// Components
import DescriptionList from '../molecule/descriptionList';
import Modal from '../molecule/modal';
import Card from '../atom/card';
import { DataTable } from '../utility-hooks/helper';

// API Hooks
import { useAppSelector } from '../redux/app/hooks';
import { useGetDebtsQuery } from '../redux/features/debt/debtApiSlice';

// utility hooks
import _common from '../utility-hooks/common';



// interface
interface DebtInfo {
    liabilityId?: string | null,
    name?: string | null,
    originalBalance?: number | null,
    type?: string | null,
    accountNumber?: string | null,
    currentPayment?: number | null,
    accountStatus?: string | null,
    openedDate?: string | null,
    term?: number | null,
    highestBalance?: number | null,
    lastPayment?: string | null,
    inquiryDate?: string | null,
    reportDate?: string | null,
    thirtyDaysLateCount?: string | null,
    sixtyDaysLateCount?: string | null,
    nintyDaysLateCount?: string | null,
    enrolled?: boolean | null,
    profileId?: string | null,
    currentBalance?: number | null,
    currentCreditor?: string | null,
    currentAccountNumber?: string | null,
    thirdPartyAccountNumber?: string | null,
    currentPaymentAmount?: number | null,
    legalStatus?: boolean | null,
    summon?: boolean | null,
    judgement?: boolean | null,
    garnishment?: boolean | null,
    address1?: string | null,
    address2?: string | null,
    city?: string | null,
    zipCode?: string | null,
    state?: string | null,
    caseNumber?: string | null,
    courtName?: string | null,
    courtDate?: string | null,
    responseDate?: string | null,

}

interface DebtTableProps {
    setCurrDebt: Dispatch<SetStateAction<Record<string, any>>>;
    openModal: Dispatch<SetStateAction<boolean>>;
}

// components
// Debt
const Debt: FC = () => {
    // stores debt data for debt details modal
    const [currDebt, setCurrDebt] = useState<Record<string, any>>({ accountNumber: "" })
    // custom hook calls get debts api
    const { isSuccess } = useGetDebtsQuery({})
    // state controls whether modal is open or close 
    const [isModalOpen, setIsModalOpen] = useState(false);


    return (
        <main style={{ opacity: isSuccess ? 1 : .5 }}>
            <DebtCounters />
            <DebtTable setCurrDebt={setCurrDebt} openModal={setIsModalOpen} />
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} >
                <DescriptionList header='Debt Details' subHeader={currDebt.accountNumber} data={currDebt} />
            </Modal>
        </main>
    )
}

// Debt Counter
const DebtCounters: FC = () => {
    // get debts api called in higher-order component
    // pull data from global state
    const {
        numDebts,
        totalDebts,
    } = useAppSelector(state => state.debt)

    return (
        <Card customClass='flex flex-col sm:flex-row justify-around mb-10 bg-white rounded-md shadow-sm border-gray border px-4 py-8'>
            <article className='text-center mb-3'>
                <span className='text-lg'>Number of Enrolled Debts</span>
                <h1 className='text-4xl font-bold text-slate-600'>{numDebts}</h1>
            </article>
            <article className='text-center'>
                <span className='text-lg'>Total Enrolled Debts</span>
                <h1 className='text-4xl leading-12 font-bold text-slate-600'>{_common.FormatCurrency(totalDebts)}</h1>
            </article>
        </Card>
    )
}

// Debt Table
const DebtTable: FC<DebtTableProps> = ({ setCurrDebt, openModal }) => {
    // get debts api called in higher-order component
    // pull data from global state
    const {
        ids,
        entities
    } = useAppSelector(state => state.debt)

    // helper function formats data object prop for Description List component
    function formatDebtDetails(data: Record<string, any>) : Record<string,any> {
        return {
            "Account Name": _common.IfNullReturn(data.name, "N/A"), 
            "Account Number": _common.IfNullReturn(data.accountNumber, "N/A"),
            "Original Balance": _common.FormatCurrency(data.originalBalance),
            "Debt Type": _common.IfNullReturn(data.type, "N/A"),
            "Current Payment": _common.FormatCurrency(data.currentPayment),
            "Status": _common.IfNullReturn(data.accountStatus, "N/A"), 
            "Account Opened": _common.IfNullReturn(data.openedDate, "N/A"),
            "Debt Term": _common.IfNullReturn(data.term, "N/A"), 
            "Highest Balance": _common.FormatCurrency(data.highestBalance),
            "Last Payment Made": _common.IfNullReturn(data.lastPayment, "N/A"),
            "Current Balance": _common.FormatCurrency(data.currentBalance),
            "Current Creditor": _common.IfNullReturn(data.currentCreditor, "N/A"), 
            "In Civil Action": _common.IfNullReturn(JSON.stringify(data.legalStatus), "False"),
            "Summons Issued": _common.IfNullReturn(JSON.stringify(data.summon), "False"),
            "Judgement Issued": _common.IfNullReturn(JSON.stringify(data.judgement), "False"),
            "Garnishment Issued": _common.IfNullReturn(JSON.stringify(data.garnishment), "False"),
            "Address 1": _common.IfNullReturn(data.address1, "N/A"),
            "Address 2": data.address2, 
            "City": data.city, 
            "Zip Code": data.zipCode,
            "State": data.state,
            "Case Number": _common.IfNullReturn(data.caseNumber, "No case number recorded for this debt"),
            "Court Name": _common.IfNullReturn(data.courtName, "No court name recorded for this debt"),
            "Court Date": _common.IfNullReturn(data.courtDate, "No court date recorded for this debt")
        }

    }

    const enrolledDebtColumns = [
        {
            id: 'creditor',
            header: 'Creditor',
            accessorKey: 'creditor',
            cell: (ctx: any) => {
                const { accountData, creditor } = ctx.row.original;
                return <button
                    className="font-bold text-cyan-600 hover:text-slate-500 focus:outline-none"
                    onClick={() => {
                        // saved debt information for modal
                        setCurrDebt(formatDebtDetails(accountData))
                        // open modal
                        openModal(true)
                    }}
                >
                    {_common.IfNullReturn(creditor, "N/A")}
                </button>
            }
        },
        {
            id: "account",
            header: "Account Number",
            accessorKey: "account",
            cell: (ctx: any) => ctx.getValue(),
        },
        {
            id: "balance",
            header: "Balance",
            accessorKey: "balance",
            cell: (ctx: any) => ctx.getValue(),
        },
        {
            id: "payment",
            header: "Current Payment",
            accessorKey: "payment",
            cell: (ctx: any) => ctx.getValue(),
        },
        {
            id: "term",
            header: "Term",
            accessorKey: "term",
            cell: (ctx: any) => ctx.getValue(),
        },
    ]

    const enrolledDebtData = ids.map(id => {
        const debt: Record<string, any> = entities[id]
        // destructing data I want in rest -> will change when client api is finalized
        const {
            enrolled,
            profileId,
            liabilityId,
            ...rest } = debt

        return {
            accountData: rest,
            creditor: debt.currentCreditor,
            account: debt.accountNumber,
            balance: _common.FormatCurrency(debt.currentBalance),
            payment: _common.FormatCurrency(debt.currentPayment),
            term: debt.term,
            legal: JSON.stringify(debt.legalStatus)
        }
    })

    return (
        <main>
            <section className="mb-4">
                <h6 className="mb-2 uppercase text-xl">Enrolled Debts</h6>
                <Card>
                    {DataTable(enrolledDebtData, enrolledDebtColumns)}
                </Card>
            </section>
        </main>
    )
}

export default Debt;