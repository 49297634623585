import React, { useState } from "react";
import {BiChevronsRight, BiChevronsLeft, BiChevronRight, BiChevronLeft} from 'react-icons/bi'
import DelayInput from "../atom/delayInput";
import Input from "../atom/input";

import {
    getCoreRowModel,
    useReactTable,
    flexRender,
    FilterFn,
    getPaginationRowModel,
    getFilteredRowModel
} from '@tanstack/react-table'

import type {
    ColumnDef,
    Row,
    Table as ReactTable,
    PaginationState,
} from '@tanstack/react-table'

import { rankItem } from "@tanstack/match-sorter-utils";

import { IconContext } from "react-icons/lib";
import Skeleton from "../atom/skeleton";

type T = { //data coming in
    id: number
    name: string,
    email: string
}
interface ReactTableProps<T extends object> {
    data: T[];
    columns: ColumnDef<T>[];
    renderSubComponent?: (props: { row: Row<T> }) => React.ReactElement;
    pageIndex?: number;
    pageSize?: number;
    pageCount?: number;
    loading?: boolean;
    search?: boolean;
    tableStyle?:string;
    tableHeaderStyle?:string;
}

const Filter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value)

  addMeta({
    itemRank
  })

  return itemRank.passed
}

const presetShow = [
  {
    label: 'Show 5',
    value: 5,
  },
  {
    label: 'Show 10',
    value: 10,
  },
  {
    label: 'Show 15',
    value: 15,
  },
  {
    label: 'Show 20',
    value: 20,
  },
]

const Table:React.FC<ReactTableProps<T>> = ({
    data,
    columns,
    pageIndex,
    pageSize,
    pageCount,
    loading,
    search,
    tableStyle,
    tableHeaderStyle
}) => {
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: pageIndex ?? 0,
        pageSize: pageSize ?? 10
    })

    const [globalFilter, setGlobalFilter] = useState('');

    

    const tableData = React.useMemo(
      () => (loading ? Array (pageSize).fill({}) : data),
      [loading, data]
    );

    const tableColumns = React.useMemo(
      () => 
        loading
          ? columns.map((column) => ({
              ...column,
              cell:() => <Skeleton />
          }))
          : columns,
      [loading, columns]
    );

    const table = useReactTable({
        data: tableData,
        columns: tableColumns,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: false,
        pageCount,
        state: {
            pagination,
            globalFilter
        },
        onPaginationChange: setPagination,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: Filter,
        enableGlobalFilter: true,
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel()
      })

    return (
      <div className="flex flex-col w-full">
        {!search 
          ? null
          : (
            <div className='flex items-center justify-start'>
              <span className='text-base mr-2'>Search: </span>
              <DelayInput placeholder={`Filter ${loading ? 0 : table.getPreExpandedRowModel().rows.length} entries`} className='py-1 px-2 border border-gray-200 rounded focus:outline-none' onChange={val => setGlobalFilter(String(val))} debounce={300} value={globalFilter ?? ''} />
            </div>
          )
        }
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-1">
            <div className="overflow-hidden">
              <div className={tableStyle ?? 'overflow-hidden rounded border-b shadow-sm my-4'}>  
                <table className='min-w-full'>
                  {/* table header */}
                  <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr className='bg-gray-100' key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th className={tableHeaderStyle ?? `font-semibold text-gray-500 tracking-wider py-3`} key={header.id}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  {/* table body */}
                  <tbody className='bg-white divide-y divide-gray-200'>
                    {table.getRowModel().rows.map((row) => (
                        <tr key={row.id}>
                          {row.getVisibleCells().map((cell) => (
                            <td
                              className='text-center px-6 py-4 whitespace-nowrap text-gray-600'
                              style={{
                                width: cell.column.getSize(),
                              }}
                              key={cell.id}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          ))}
                        </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/* table footer */}
            <IconContext.Provider value={{size: '1.5em'}}>
              <div className='flex items-center gap-2 justify-between'>
                  <div className='flex items-center gap-4'>
                    <span className="flex items-center gap-1 text-sm">
                        <p>Page <span className='font-semibold'>{table.getState().pagination.pageIndex + 1}</span> of <span className='font-semibold'>{table.getPageCount()}</span></p>
                    </span>
                    <Input value={table.getState().pagination.pageSize} className='bg-inherit border-2 rounded-md border-gray-200 p-2' type='select' data={presetShow} onChange={(val:number|string) => table.setPageSize(Number(val))} />
                  </div>
                  <div className='paginationContainer flex divide-x-2 overflow-hidden rounded border-2 border-gray-200'>
                    <button
                        type='button'
                        onClick={() => table.setPageIndex(0)}
                        disabled={!table.getCanPreviousPage()}
                        className={`${table.getCanPreviousPage() ? 'text-black' : 'text-gray-500' } p-2 hover:bg-gray-50`}
                        >
                        <BiChevronsLeft />
                    </button>  
                    <button
                        type='button'
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                        className={`${table.getCanPreviousPage() ? 'text-black' : 'text-gray-500' } p-2 hover:bg-gray-50`}
                        >
                        <BiChevronLeft />
                    </button>
                    <button
                        type='button'
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                        className={`${table.getCanNextPage() ? 'text-black' : 'text-gray-500' } p-2 hover:bg-gray-50`}
                        >
                        <BiChevronRight />
                    </button>  
                    <button
                        type='button'
                        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                        disabled={!table.getCanNextPage()}
                        className={`${table.getCanNextPage() ? 'text-black' : 'text-gray-500' } p-2 hover:bg-gray-50`}
                        >
                        <BiChevronsRight />
                    </button>  
                  </div>
              </div>
            </IconContext.Provider>
          </div>
        </div>
      </div>
    )
}

export default Table;