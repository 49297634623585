import React, {FC, ReactNode, useState, useContext, createContext, useCallback, useEffect} from "react";
import Toast, { ToastProps } from "./toast";

type ContextProps = {
    children: ReactNode
}
  
type ContextValue = {
toasts: ToastProps[];
setToasts: Function;
};

const toastContextDefaultValue: ContextValue = {
toasts: [],
setToasts: () => {}
};

const ToastContext = createContext(toastContextDefaultValue.setToasts);

const ToastContextProvider: FC<ContextProps> = ({ children }) => {
const [toasts, setToasts] = useState<ToastProps[]>(
    toastContextDefaultValue.toasts
);



const addToast = useCallback(
    (toast: ToastProps) => {
    setToasts((toasts) => [...toasts, toast]);

    setTimeout(() => {
        removeToast(toast.id);
        }, 5000);
    },
    [setToasts]
);

const removeToast = (id: string) => {
    setToasts((toasts) => toasts.filter((toast) => toast.id !== id));
};

return (
    <ToastContext.Provider value={addToast}>
    <div className="toast-container flex gap-2 flex-col absolute top-2 right-2 z-30">
        {toasts.map((toast, i) => (
        <Toast
            key={i}
            message={toast.message}
            type={toast.type}
            id={toast.id}
            onClick={removeToast}
        />
        ))}
    </div>
    {children}
    </ToastContext.Provider>
);
};

export default function useToast() {
return useContext(ToastContext);
}

export { ToastContextProvider };