import { FC } from "react";

// Component
import LoginForm from "../organism/loginForm";

const Login: FC = () => {

  return (
    <main className="h-screen grid place-items-center" >
      <section className="grid grid-cols-2 grid-rows-1 bg-white h-[80%] w-[80%] xs:border-0 sm:border-2 rounded-md drop-shadow-md" >
        <article className="col-span-2 sm:col-span-1 flex min-h-full items-center justify-center px-4 py-12 sm:px-6 lg:px-8 ">
          <LoginForm />
        </article>
        <article 
        className="col-span-0 sm:col-span-1 bg-cover rounded-r-md" 
        style={{ 
          backgroundImage: 'url("https://images.unsplash.com/photo-1669666770544-1fc8b126766c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80")' 
          }}>
        </article>
      </section>
    </main>
  )

};





export default Login;
