import Table from "../organism/table"
import React from 'react';

//Table helper, autogenerates columns if data structure not known
const DataTable = (data:any, columns?:any) => {
    const fallback = [];

    const [isLoading, setLoading] = React.useState(true)

    if (columns === undefined) {
        for (let props in data[0]) {
            fallback.push({
                id: props,
                header: props.toUpperCase(),
                accessorKey: props,
                cell: (ctx:any) => ctx.getValue()
            })    
        }
    }

    React.useEffect(() => {
        if (data) {
            setLoading(false)
        }
    }, [data])

    return (
        <Table 
            pageSize={10} 
            search={true} 
            loading={isLoading} 
            data={data} 
            columns={columns ?? fallback}/>
    )
}

export { DataTable };