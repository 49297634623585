import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setCredentials, logOut } from '../../features/auth/authSlice';

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api`;

const baseQuery = fetchBaseQuery({
	baseUrl,
	credentials: 'include',
	prepareHeaders: (headers, { getState }) => {
		const token = getState().auth.token;
		if (token) {
			headers.set('Authorization', `Bearer ${token}`);
		}
		return headers;
	},
});

// wrapper query
const baseQueryWithReauth = async (args, api, extraOptions) => {
	let result = await baseQuery(args, api, extraOptions);

	if (result?.error?.originalStatus === 403) {
		// send refresh token to get new access token
		const refreshResult = await baseQuery(
			'/user/refresh-token',
			api,
			extraOptions
		);
		if (refreshResult?.data) {
			const user = api.getState().auth.user;
			// store new token
			api.dispatch(setCredentials({ ...refreshResult.data, user }));
			// retry original query with new access token
			result = await baseQuery(args, api, extraOptions);
		} else {
			api.dispatch(logOut());
		}
	}

	return result;
};

export const apiSlice = createApi({
	baseQuery: baseQueryWithReauth,
	tagTypes: ['ProfileInfo', 'Debt', 'Enrollment', 'Payment', 'Documents'],
	endpoints: (builder) => ({}),
});
