import { apiSlice } from "../../app/api/apiSlice";

export const profileApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getInfo: builder.query({
            query: () => ({
                url: `/profile/`,
                method: 'GET',
            }),
            providesTags: ['ProfileInfo'], 
            transformResponse: (res) => res.data
        }),
    })
})

export const {
    useGetInfoQuery,
} = profileApiSlice